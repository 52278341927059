<template>
  <main class="view">
    <div class="punchclock-wrapper">
      <div v-if="permission.punch || permission.admin" class="punchclock-wrapper">
        <div class="col col-4 punch-in margin-bottom-auto">
          <a @click="TogglePunch" class="button punch-button" :class="['punched-' + (punched_in ? 'in' : 'out')]">
            <span>
              <img src="@/assets/images/icons/stopwatch-alt.svg" alt="">
              <p>{{ $t('Punch ' + (punched_in ? 'out' : 'in')) }}</p>
            </span>
          </a>
        </div>
        <div class="col col-8 shifts">
          <div class="shifts-heading">
            <p class="title">{{ $t('Punch clock') }}</p>
          </div>
          <div class="shifts-result">
            <div class="list-wrapper">
              <div class="list-head">
                <ul class="list-label">
                  <li class="list-row">
                    <div class="list-col">{{ $t('Punched in') }}</div>
                    <div class="list-col">{{ $t('Punched out') }}</div>
                    <div class="list-col">{{ $t('Salary type') }}</div>
                  </li>
                </ul>
              </div>
              <div class="list-body">
                <ul class="list-content odd-even">
                  <li class="list-row" :key="punch.id" v-for="punch in punches">
                    <div class="list-col">{{ DateFormat(punch.start_date) }}</div>
                    <div class="list-col">{{ DateFormat(punch.end_date) }}</div>
                    <div class="list-col">{{ $t(punch.type.charAt(0).toUpperCase() + punch.type.slice(1)) }}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="permission.punch" class="grid-wrapper list-container">
        <div class="grid-info flex-center nowrap">
          <span class="grid-heading">
            <h1>{{ $t('Punches') }}</h1>
            <span class="entries">{{ NumberFormat(search.punch.entries) }} {{ $t(`entr${search.punch.entries == 1 ? 'y' : 'ies'}`) }}</span>
          </span>
          <div class="grid-actions">
            <div class="grid-date">
              <p class="grid-heading" style="color: inherit;">{{ $t('Start date') }}</p>
              <div class="date-range flex-row">
                <div class="input">
                  <VueCtkDateTimePicker id="search-punch-interval-start" v-model="search.punch.interval.start" @input="OnDateInput" :locale="$i18n.locale.split('_')[0].replace(/no/, 'nb')" formatted="ddd, MMM D, YYYY, HH:mm" format="YYYY-MM-DD HH:mm:ss" :first-day-of-week="1" input-size="sm" label="" hint="" :range="false" :no-shortcuts="true" :no-button="false" :auto-close="false" />
                </div>
              </div>
            </div>
            <div class="grid-date" style="margin-left: 20px;">
              <p class="grid-heading" style="color: inherit;">{{ $t('End date') }}</p>
              <div class="date-range flex-row">
                <div class="input">
                  <VueCtkDateTimePicker id="search-punch-interval-end" v-model="search.punch.interval.end" @input="OnDateInput" :locale="$i18n.locale.split('_')[0].replace(/no/, 'nb')" formatted="ddd, MMM D, YYYY, HH:mm" format="YYYY-MM-DD HH:mm:ss" :first-day-of-week="1" input-size="sm" label="" hint="" :range="false" :no-shortcuts="true" :no-button="false" :auto-close="false" />
                </div>
              </div>
            </div>
            <!-- <a class="button green" @click.prevent="" href="">{{ $t('Apply') }}</a> -->
          </div>
        </div>
        <div class="search-result">
          <div class="list-wrapper">
            <div class="list-head">
              <ul class="list-label">
                <li class="list-row">
                  <div class="list-col">{{ $t('Punched in') }}</div>
                  <div class="list-col">{{ $t('Punched out') }}</div>
                  <div class="list-col">{{ $t('Salary type') }}</div>
                </li>
              </ul>
            </div>
            <div class="list-body">
              <ul class="list-content odd-even">
                <li class="list-row" :data-id="punch.id" :key="punch.id" v-for="punch in search.punch.list">
                  <div class="list-col">{{ DateFormat(punch.start_date) }}</div>
                  <div class="list-col">{{ DateFormat(punch.end_date) }}</div>
                  <div class="list-col capitalize">{{ $t(punch.type) }}</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div v-if="permission.admin" class="grid-wrapper tabs-container">
        <div class="tabs">
          <ul class="tabs__head">
            <li class="tabs__head--item" :class="{active: tab.active == 'workers'}" data-tab="workers" @click="SetActiveTab">
              <span>{{ $t('Punches') }}</span>
            </li>
            <li class="tabs__head--item" :class="{active: tab.active == 'validation'}" data-tab="validation" @click="SetActiveTab">
              <span>{{ $t('Validation') }}</span>
            </li>
            <li class="tabs__head--item" :class="{active: tab.active == 'report'}" data-tab="report" @click="SetActiveTab" v-if="IsMainCompany()">
              <span>{{ $t('Report') }}</span>
            </li>
          </ul>
          <ul class="tabs__body">
            <li class="tabs__body--content padding" v-if="tab.active == 'workers'">
              <div class="grid-info">
                <span class="grid-heading">
                  <h1>{{ $t('Punches') }}</h1>
                  <span class="entries">{{ NumberFormat(search.admin.entries) }} {{ $t(`entr${search.admin.entries == 1 ? 'y' : 'ies'}`) }}</span>
                </span>
                <div class="grid-search">
                  <input id="shift-search-input" type="text" @keydown.enter="GetAdminSearchList($event.target.value, $event.target), $event.target.blur()" :placeholder="$t('Search by name')">
                </div>
              </div>
              <table class="user-list table odd-even">
                <thead>
                  <tr class="user-list__actions">
                    <th class="name">{{ $t('Name') }}</th>
                    <th>{{ $t('Punched in') }}</th>
                    <th>{{ $t('Punched out') }}</th>
                    <th>{{ $t('Salary type') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr :data-id="punch.res_user_id" class="user-list__row" :key="punch.id" v-for="punch in search.admin.list">
                    <td class="name">{{ TitleCase(punch.res_user_name) }}</td>
                    <td>{{ DateFormat(punch.start_date) }}</td>
                    <td>
                      <button class="button icon stopwatch red" @click="ForcePuncOut(punch.res_user_id, punch.id)">{{ $t('Punch out') }}</button>
                    </td>
                    <td class="width-auto">
                      <label class="option-controller">
                        <span class="option-controller__value">{{ $t('Commission') }}</span>
                        <input @click.prevent="TogglePaymentType(punch.res_user_id, $event.target)" type="checkbox" :data-punch_id="punch.id" :checked="!punch.commission">
                        <span class="option-controller__toggle"></span>
                        <span class="option-controller__value">{{ $t('Hourly rate') }}</span>
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>
            </li>

            <li class="tabs__body--content padding" v-if="tab.active == 'validation'">
              <div class="grid-info flex-center nowrap">
                <span class="grid-heading">
                  <h1>{{ $t('Validation') }}</h1>
                  <span class="entries">{{ NumberFormat(tab.validation.entries.found) }} {{ $t('of') }} {{ NumberFormat(tab.validation.entries.total) }} {{ $t(`entr${tab.validation.entries.total == 1 ? 'y' : 'ies'}`) }}</span>
                </span>
                <div class="grid-actions">
                  <div class="grid-date">
                    <p class="grid-heading" style="color: inherit;">{{ $t('Start date') }}</p>
                    <div class="date-range flex-row">
                      <div class="input">
                        <VueCtkDateTimePicker id="tab-validation-date-range-start" @input="OnDateSelection" v-model="tab.validation.date.range.start" label="" hint="" :locale="$i18n.locale.split('_')[0].replace(/no/, 'nb')" formatted="ddd, MMM D, YYYY, HH:mm" format="YYYY-MM-DD HH:mm:ss" :first-day-of-week="1" input-size="sm" :range="false" :no-shortcuts="true" :no-button="false" :auto-close="false" />
                      </div>
                    </div>
                  </div>
                  <div class="grid-date" style="margin-left: 20px;">
                    <p class="grid-heading" style="color: inherit;">{{ $t('End date') }}</p>
                    <div class="date-range flex-row">
                      <div class="input">
                        <VueCtkDateTimePicker id="tab-validation-date-range-end" @input="OnDateSelection" v-model="tab.validation.date.range.end" label="" hint="" :locale="$i18n.locale.split('_')[0].replace(/no/, 'nb')" formatted="ddd, MMM D, YYYY, HH:mm" format="YYYY-MM-DD HH:mm:ss" :first-day-of-week="1" input-size="sm" :range="false" :no-shortcuts="true" :no-button="false" :auto-close="false" />
                      </div>
                    </div>
                  </div>
                  <a class="button red" @click.prevent="OnResetDateRange" href="">{{ $t('Reset') }}</a>
                  <div class="select week flex-column">
                    <span class="label-text">{{ $t('Week') }}</span>
                    <v-select name="week" @input="OnWeekSelection" v-model="tab.validation.week.selected" :options="tab.validation.week.options" :placeholder="$t('Week')" :clearable="false" />
                  </div>
                </div>
              </div>
              <div id="punch-validation-list" class="multi-dimensional-list">
                <ul class="multi-dimensional-list__head">
                  <li class="list-col list-cell user-name sort" :class="tab.validation.sort.by == 'text' && ['dir', tab.validation.sort.dir]" @click="Sort(tab.validation, 'items', 'text', 'name')">{{ $t('Name') }}</li>
                  <li class="list-col list-cell week-number">{{ $t('Weeks') }}</li>
                  <li class="list-col list-cell punch-in-date-time sort" :class="tab.validation.sort.by == 'date' && ['dir', tab.validation.sort.dir]" @click="Sort(tab.validation, 'items', 'date', 'start_date')">{{ $t('Punched in') }}</li>
                  <li class="list-col list-cell punch-out-date-time">{{ $t('Punched out') }}</li>
                  <li class="list-col list-cell punch-duration sort" :class="tab.validation.sort.by == 'time' && ['dir', tab.validation.sort.dir]" @click="Sort(tab.validation, 'items', 'time', 'total_duration')">{{ $t('Duration') }}</li>
                  <li class="list-col list-cell punch-submit">{{ $t('Action') }}</li>
                </ul>
                <ul class="multi-dimensional-list__body odd-even">
                  <li class="user" :class="{attention: user.punches.find(punch => !punch.punched_out)}" :data-id="user.id" :key="user.id" v-for="user in tab.validation.items">
                    <ul class="list-row head toggle collapsed" @click="ToggleListItem">
                      <li class="list-col list-cell user-name capitalize">{{ user.name }}</li>
                      <li class="list-col list-cell week-number">{{ user.weeks.length }} {{ $t('week' + (user.weeks.length > 1 ? 's' : '')) }}</li>
                      <li class="list-col list-cell punch-in-date-time">{{ DateFormat(user.punches.slice(-1)[0].date.start.utc) }}</li>
                      <li class="list-col list-cell punch-out-date-time">{{ user.punches[0].date.end.utc ? DateFormat(user.punches[0].date.end.utc) : ''}}</li>
                      <li class="list-col list-cell punch-duration">{{ TimeFormat(user.total_duration) }}</li>
                      <li class="list-col list-cell punch-submit">
                        <button class="button green pre" @click="PunchAction($event, true, true, user.punches.length)" :disabled="user.punches.length == 1 && !user.punches[0].punched_out">{{ $t('Submit') }}</button>
                        <button class="button red pre" @click="PunchAction($event, false, true, user.punches.length)">{{ $t('Delete') }}</button>
                        <span class="punch-submit-count">{{ user.punches.length }}</span>
                        <div v-if="user.punches.find(punch => !punch.punched_out)" class="flag red" title="Not punched out" @click="ToggleListItem($event.target.closest('.toggle'))"></div>
                      </li>
                    </ul>
                    <ul class="list-row body">
                      <li class="list-col punch" :data-id="punch.id" :key="punch.id" v-for="punch in user.punches">
                        <ul class="list-row nested" :class="{'not-punched-out': !punch.punched_out}">
                          <li class="list-col list-cell nested user-name">
                            <span class="punch-type">{{ $t(Capitalize(punch.type) + (punch.type == 'hourly' ? ' rate' : '')) }}</span>
                          </li>
                          <li class="list-col list-cell nested week-number">{{ $t('Week') }} {{ punch.week }}</li>
                          <li class="list-col list-cell nested punch-in-date-time">
                            <div class="punch-in-date-time-picker">
                              <SimpleDateTimePicker :id="punch.id" name="start" :value="punch.date.start.ltz" @focus="HandlePunchUpdate" @blur="HandlePunchUpdate" @reset="HandlePunchUpdate" @input="ChangePunchDateTime" />
                            </div>
                          </li>
                          <li class="list-col list-cell nested punch-out-date-time">
                            <div class="punch-out-date-time-picker" v-if="punch.punched_out">
                              <SimpleDateTimePicker :id="punch.id" name="end" :value="punch.date.end.ltz" @focus="HandlePunchUpdate" @blur="HandlePunchUpdate" @reset="HandlePunchUpdate" @input="ChangePunchDateTime" />
                            </div>
                          </li>
                          <li class="list-col list-cell nested punch-duration">{{ TimeFormat(punch.duration) }}</li>
                          <li class="list-col list-cell nested punch-submit">
                            <button class="button green" @click="PunchAction($event, true)" :disabled="!punch.punched_out" :title="!punch.punched_out && 'Not punched out'">{{ $t('Submit') }}</button>
                            <button class="button red" @click="PunchAction($event, false)">{{ $t('Delete') }}</button>
                            <div v-if="!punch.punched_out" class="flag red" title="Not punched out"></div>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>

            <li class="tabs__body--content padding" v-if="tab.active == 'report' && IsMainCompany()">
              <div class="grid-info flex-center nowrap">
                <span class="grid-heading">
                  <h1>{{ $t('Report') }}</h1>
                  <span class="entries">{{ NumberFormat(tab.report.entries) }} {{ $t(`entr${tab.report.entries == 1 ? 'y' : 'ies'}`) }}</span>
                </span>
                <div class="grid-actions">
                  <div class="grid-date">
                    <p class="grid-heading" style="color: inherit;">{{ $t('Start date') }}</p>
                    <div class="date-range flex-row">
                      <div class="input">
                        <VueCtkDateTimePicker id="tab-report-date-range-start" @input="OnReportDateSelection" v-model="tab.report.date.range.iso.start" label="" hint="" :locale="$i18n.locale.split('_')[0].replace(/no/, 'nb')" formatted="ddd, MMM D, YYYY, HH:mm" format="YYYY-MM-DD HH:mm:ss" :first-day-of-week="1" input-size="sm" :range="false" :no-shortcuts="true" :no-button="false" :auto-close="false" />
                      </div>
                    </div>
                  </div>
                  <div class="grid-date" style="margin-left: 20px;">
                    <p class="grid-heading" style="color: inherit;">{{ $t('End date') }}</p>
                    <div class="date-range flex-row">
                      <div class="input">
                        <VueCtkDateTimePicker id="tab-report-date-range-end" @input="OnReportDateSelection" v-model="tab.report.date.range.iso.end" label="" hint="" :locale="$i18n.locale.split('_')[0].replace(/no/, 'nb')" formatted="ddd, MMM D, YYYY, HH:mm" format="YYYY-MM-DD HH:mm:ss" :first-day-of-week="1" input-size="sm" :range="false" :no-shortcuts="true" :no-button="false" :auto-close="false" />
                      </div>
                    </div>
                  </div>
                  <a class="button green" @click.prevent="ApplyReportDateRange" href="">{{ $t('Apply') }}</a>
                  <div class="select week flex-column">
                    <span class="label-text">{{ $t('Week') }}</span>
                    <v-select name="week" @input="OnReportWeekSelection" v-model="tab.report.week.selected" :options="tab.report.week.options" :placeholder="$t('Week')" :clearable="false" />
                  </div>
                </div>
              </div>
              <div id="punch-report-list" class="multi-dimensional-list">
                <ul class="multi-dimensional-list__head">
                  <li class="list-col list-cell user-name sort" :class="tab.report.sort.by == 'text' && ['dir', tab.report.sort.dir]" @click="Sort(tab.report, 'items', 'text', 'name')">{{ $t('Name') }}</li>
                  <li class="list-col list-cell punch-in-date-time sort" :class="tab.report.sort.by == 'date' && ['dir', tab.report.sort.dir]" @click="Sort(tab.report, 'items', 'date', 'start_date')">{{ $t('Punched in') }}</li>
                  <li class="list-col list-cell punch-out-date-time">{{ $t('Punched out') }}</li>
                  <li class="list-col list-cell punch-duration sort" :class="tab.report.sort.by == 'time' && ['dir', tab.report.sort.dir]" @click="Sort(tab.report, 'items', 'time', 'time')">{{ $t('Duration') }}</li>
                  <li class="list-col list-cell order-count sort" :class="tab.report.sort.by == 'number' && ['dir', tab.report.sort.dir]" @click="Sort(tab.report, 'items', 'number', 'order_count')">{{ $t('Orders') }}</li>
                  <li class="list-col list-cell order-view">{{ $t('Details') }}</li>
                </ul>
                <ul class="multi-dimensional-list__body odd-even">
                  <li class="user" :data-id="user.id" :key="user.id" v-for="user in tab.report.items">
                    <ul class="list-row head toggle collapsed" @click="ToggleReportListItem">
                      <li class="list-col list-cell user-name capitalize">{{ user.name }}</li>
                      <li class="list-col list-cell punch-in-date-time">{{ DateFormat(user.punches.slice(-1)[0].start_date) }}</li>
                      <li class="list-col list-cell punch-out-date-time">{{ DateFormat(user.punches[0].end_date) }}</li>
                      <li class="list-col list-cell punch-duration">{{ TimeFormat(user.time) }}</li>
                      <li class="list-col list-cell order-count">{{ NumberFormat(user.order_count) }} {{ $t('order' + (user.order_count != 1 ? 's' : '')) }}</li>
                      <li class="list-col list-cell order-view">{{ user.punch_count }} {{ $t('punch' + (user.punch_count != 1 ? 'es' : '')) }}</li>
                    </ul>
                    <ul class="list-row body">
                      <li class="list-col punch" :data-id="punch.id" :key="punch.id" v-for="punch in user.punches">
                        <ul class="list-row nested">
                          <li class="list-col list-cell nested user-name">{{ $t(Capitalize(punch.type) + (punch.type == 'hourly' ? ' rate' : '')) }}</li>
                          <li class="list-col list-cell nested punch-in-date-time">{{ DateFormat(punch.start_date) }}</li>
                          <li class="list-col list-cell nested punch-out-date-time">{{ DateFormat(punch.end_date) }}</li>
                          <li class="list-col list-cell nested punch-duration">{{ TimeFormat(punch.time) }}</li>
                          <li class="list-col list-cell nested order-count">{{ NumberFormat(punch.orders.length) }} {{ $t('order' + (punch.orders.length != 1 ? 's' : '')) }}</li>
                          <li class="list-col list-cell nested order-view">
                            <button class="button" @click="ViewOrders($event.target, user, punch.id)" :disabled="!punch.orders.length">{{ $t('Parcels') }}</button>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>

          </ul>
        </div>
      </div>
    </div>

    <Modal modal="view" :value="modal.view.open" :title="modal.view.title">
      <div id="view-punch-orders">
        <div class="modal-header"></div>
        <div class="modal-content">
          <div class="list-wrapper scrollable">
            <div class="multi-dimensional-list">
              <ul class="multi-dimensional-list__head">
                <li class="list-col list-cell view-type">{{ $t('Type') }}</li>
                <li class="list-col list-cell view-start">{{ $t('Start date') }}</li>
                <li class="list-col list-cell view-end">{{ $t('End date') }}</li>
                <li class="list-col list-cell view-time">{{ $t('Duration') }}</li>
                <li class="list-col list-cell view-open">{{ $t('Orders') }}</li>
              </ul>
              <ul class="multi-dimensional-list__body odd-even">
                <li :data-id="punch.id" :key="punch.id" v-for="punch in modal.view.list">
                  <ul class="list-row head" style="z-index: 2;" :class="{collapsed: modal.view.multi, toggle: modal.view.multi}" @click="ToggleModalListItem">
                    <li class="list-col list-cell view-type">{{ $t(Capitalize(punch.type) + (punch.type == 'hourly' ? ' rate' : '')) }}</li>
                    <li class="list-col list-cell view-start">{{ DateFormat(punch.start_date) }}</li>
                    <li class="list-col list-cell view-end">{{ DateFormat(punch.end_date) }}</li>
                    <li class="list-col list-cell view-time">{{ TimeFormat(punch.time) }}</li>
                    <li class="list-col list-cell view-open">{{ NumberFormat(punch.orders.length) }} {{ $t('order' + (punch.orders.length != 1 ? 's' : '')) }}</li>
                  </ul>
                  <ul class="list-row body">
                    <li :data-id="order.id" class="list-col" :key="order.id" v-for="order in punch.orders">
                      <ul class="list-row nested">
                        <li class="list-col list-cell nested view-type">{{ GetCompany('name', order.company_id) }}</li>
                        <li class="list-col list-cell nested view-start">{{ DateFormat(order.start_date) }}</li>
                        <li class="list-col list-cell nested view-end">{{ DateFormat(order.end_date) }}</li>
                        <li class="list-col list-cell nested view-time">{{ TimeFormat(order.total_seconds) }}</li>
                        <li class="list-col list-cell nested view-open" v-html="Hyperlink({
                          href: ParcelOrder(order.id), target: '_blank', text: order.company_id + ':' + order.increment_id
                        })" />
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="modal-footer footer-content">
          <div class="timeline">
            <ul class="timeline-events">
              <li @click="TimelineEventClick(event, $event)" @mouseenter="TimelineEventHover(event, $event)" @mouseleave="TimelineEventHover(event, $event)" :data-id="event.id" :title="event.type == 'break' && TimeFormat(event.seconds)" :class="['event', event.type]" :style="{width: event.percent + '%'}" :key="event.id" v-for="event in modal.view.events"></li>
            </ul>
          </div>
        </div>
      </div>
    </Modal>

    <div class="loading" v-if="loading">
      <div class="loading-element">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>

  </main>
</template>

<script>
  import { BPA } from '@/helpers/BPA';
  import { Tool } from '@/helpers/Tool';
  import { Permissions } from '@/helpers/Permissions';
  import SimpleDateTimePicker from '@/components/snippets/SimpleDateTimePicker';
  import Modal from '@/components/snippets/Modal';

  export default {
    name: 'Punchclock',
    components: {
      SimpleDateTimePicker,
      Modal
    },
    mixins: [Permissions, BPA],
    data() {
      return {
        loading: false,
        punches: [],
        user_id: null,
        punch_id: null,
        punched_in: null,
        permission: {
          punch: false,
          admin: false
        },
        search: {
          init: {
            admin: null
          },
          admin: {
            list: [],
            entries: 0
          },
          punch: {
            list: [],
            entries: 0,
            interval: {
              start: null,
              end: null
            }
          }
        },
        tab: {
          active: 'workers',
          workers: {
            name: 'workers',
            loaded: false,
            entries: 0,
            list: []
          },
          validation: {
            name: 'validation',
            loaded: false,
            animate: null,
            entries: {
              found: 0,
              total: 0
            },
            data: {},
            list: [],
            items: [],
            toggles: [],
            observers: [],
            page: {
              current: 1,
              number: 1,
              last: 1
            },
            sort: {
              prop: 'start_date',
              dir: 'desc',
              by: 'date'
            },
            date: {
              range: {
                start: null,
                end: null
              }
            },
            week: {
              selected: '',
              options: []
            }
          },
          report: {
            name: 'report',
            loaded: false,
            animate: null,
            entries: 0,
            data: {},
            list: [],
            items: [],
            toggles: [],
            observers: [],
            page: {
              current: 1,
              number: 1,
              last: 1
            },
            sort: {
              prop: 'start_date',
              dir: 'desc',
              by: 'date'
            },
            date: {
              range: {
                applied: '',
                iso: {
                  start: null,
                  end: null
                },
                utc: {
                  start: null,
                  end: null
                }
              }
            },
            week: {
              selected: '',
              options: []
            }
          }
        },
        modal: {
          view: {
            open: false,
            multi: false,
            animate: null,
            events: [],
            scale: 1,
            list: []
          }
        }
      }
    },
    created() {
      this.$eventHub.$on('CloseModal', (modal_name) => {
        if (this.modal[modal_name]) {
          this.modal[modal_name].open = false;
          if (modal_name == 'view') {
            this.modal.view.list = [];
          }
        }
      });
      this.user_id = JSON.parse(BPA.storage.getItem('user')).user_id;
      this.permission.punch = this.Check([/*2*/ 'punchclock']);
      this.permission.admin = this.Check([/*0, 1*/ 'admin', 'punchclock_admin']);
      if (this.permission.punch || this.permission.admin) {
        this.UpdateLastPunchList();
      } else return;
      BPA.api.GetCurrentPunch().then(response => {
        return BPA.api.response({response, return: 'json', 
          error: (message) => {
            if (response && response.status == 404) return response;
            this.$eventHub.$emit('ShowMessages', {
              message: message,
              type: 'error',
              close: true
            });
          }
        });
      }).then(response => {
        if (!response.ok || !response.result) return;
        //console.log(response.result)
        this.punch_id = response.result.id;
        this.punched_in = true;
      }).catch(e => e);
    },
    mounted() {
      if (this.permission.admin) {
        this.GetAdminSearchList();
      }
    },
    destroyed() {
      this.UnobserveIntersections_punch_report_list();
      this.UnobserveIntersections_punch_validation_list();
    },
    computed: {
      workerSearchInput() {
        return document.querySelector('#shift-search-input');
      }
    },
    methods: {
      Hyperlink(props = {}) {
        return Tool.Hyperlink(props);
      },
      CloneObject(object = {}) {
        return Tool.CloneObject(object);
      },
      Capitalize(string) {
        if (string) return Tool.Capitalize(string);
      },
      TitleCase(string) {
        if (string) return Tool.TitleCase(string);
      },
      DateFormat(date, locale, options) {
        if (date) return Tool.DateFormat(date, locale, options);
      },
      DateOnly(date) {
        if (date) return Tool.DateOnly(date);
      },
      TimeOnly(date) {
        if (date) return Tool.TimeOnly(date);
      },
      DateToUTC(date) {
        if (date) return Tool.DateToUTC(date);
      },
      DateToLTZ(date) {
        if (date) return Tool.DateToLTZ(date);
      },
      DateToISO(date, local_time_zone) {
        if (date) return Tool.DateToISO(date, local_time_zone);
      },
      DateString(date = new Date(date)) {
        return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate(); 
      },
      DateTimeDiff(start, end) {
        return start && end ? Tool.DateTimeDiff(start, end) : 0;
      },
      DateOfWeek(week, year, leap) {
        if (week) return Tool.DateOfWeek(week, year, leap);
      },
      WeekNumber(date) {
        if (date) return Tool.WeekNumber(date);
      },
      TimeFormat(seconds = 0, all_parts = false) {
        let time = Tool.TimeFormat(seconds, all_parts).split(' ').map(x => x.replace(/\D/g, '') + this.$t(x.replace(/\d/g, ''))).join(' ');
        return time ? time : ('00' + this.$t('s'));
      },
      NumberFormat(number) {
        return Tool.NumberFormat(number);
      },
      CurrencyFormat(number) {
        return Tool.CurrencyFormat(number);
      },
      Check(required) {
        return BPA.permissions(required).length;
      },
      GetCompany(property, identifier) {
        return BPA.company(property, identifier);
      },
      ParcelOrder(order_id) {
        return window.location.origin + '/parcels/orders?id=' + order_id;
      },
      Alphabetize(list) {
        return list.sort((a, b) => {
          a = a.name.toUpperCase(); 
          b = b.name.toUpperCase();
          return a < b ? -1 : a > b ? 1 : 0;
        });
      },
      SetActiveTab(e) {
        let tab = e.target.dataset.tab;
        if (!tab) tab = e.target.closest('[data-tab]').dataset.tab;
        this.tab.active = tab;
        if (tab == 'workers') {
          if (!this.tab.workers.loaded) {
            this.search.init.admin = null;
            this.GetAdminSearchList();
          }
        } else {
          this.tab.workers.loaded = false;
        }
        if (tab == 'validation') {
          if (!this.tab.validation.loaded) {
            this.GetPunchValidationCheck();
          }
        } else {
          this.tab.validation.loaded = false;
        }
        if (tab == 'report') {
          if (!this.tab.report.loaded) {
            this.InitializeReportFilters();
          }
        } else {
          this.tab.report.loaded = false;
        }
      },
      InitializeReportFilters() {
        const o = {};
        o.week = 0;
        o.options = [];
        o.today = new Date();
        o.year = o.today.getFullYear();
        o.this_week = this.WeekNumber(o.today);
        o.weeks_last_year = Tool.WeeksInYear(o.year - 1);
        o.option = (week, year) => {
          o.this_year = year == o.year;
          o.leap_year = o.weeks_last_year - 52;
          if (o.this_year) week += o.leap_year;
          o.date = this.DateOfWeek(week, year, o.leap_year || 0);
          o.day = o.date.getDay();
          o.month = o.date.getMonth();
          o.diff = o.date.getDate() - o.day + (o.day == 0 ? -6 : 1);
          o.monday = new Date(o.date.setDate(o.diff));
          if (o.diff <= 0) {
            o.date.setYear(year);
            o.date.setMonth(o.month);
          }
          o.date.setDate(o.diff + 6);
          o.date.setHours(23, 59, 0);
          o.sunday = new Date(o.date);
          if (o.this_year) {
            week -= o.leap_year;
          } 
          return {
            label: week,
            value: JSON.stringify({
              start: this.DateToISO(o.monday), 
              end: this.DateToISO(o.sunday)
            })
          }
        }
        for (o.week = o.this_week; o.week >= 1; o.week--) {
          o.options.push(o.option(o.week, o.year));
        }
        for (o.week = o.weeks_last_year; o.week > o.this_week; o.week--) {
          o.options.push(o.option(o.week, o.year - 1));
        }
        //console.log(this.CloneObject(o.options))
        this.tab.report.week.options = o.options;
        this.OnReportWeekSelection(o.options[0]);
        this.tab.report.loaded = true;
      },
      OnReportWeekSelection(option) {
        if (!option) return;
        const o = {};
        o.today = new Date();
        o.year = o.today.getFullYear();
        o.week = this.WeekNumber(o.today);
        o.range = JSON.parse(option.value);
        o.end_year = new Date(o.range.end).getFullYear();
        o.this_week = option.label == o.week && o.end_year == o.year;
        //o.range.end = this.DateToISO(o.this_week ? o.today : o.range.end);
        this.tab.report.date.range.iso = o.range;
        this.OnReportDateSelection();
      },
      OnReportDateSelection() {
        const o = {};
        o.date_range = this.CloneObject(this.tab.report.date.range.iso);
        o.start_week = this.WeekNumber(o.date_range.start);
        o.end_week = this.WeekNumber(o.date_range.end);
        o.this_week = this.WeekNumber(new Date());
        o.week_options = this.tab.report.week.options.map(option => option.label);
        o.week_number = o.start_week == o.this_week && o.end_week == o.this_week ? o.this_week : '';
        if (!o.week_number) for (let i = 0; i < o.week_options.length; i++) {
          if (o.start_week == o.week_options[i] && o.end_week == o.week_options[i]) {
            o.week_number = o.week_options[i];
            break;
          }
        }
        this.tab.report.week.selected = o.week_options.includes(o.week_number) ? o.week_number : '';
        if (o.date_range.start) o.date_range.start = this.DateToUTC(o.date_range.start);
        if (o.date_range.end) o.date_range.end = this.DateToUTC(o.date_range.end);
        this.tab.report.date.range.utc = o.date_range;
      },
      ApplyReportDateRange() {
        const o = {};
        o.date_range = this.CloneObject(this.tab.report.date.range.utc);
        o.date_range_applied = this.tab.report.date.range.applied;
        o.date_range_string = JSON.stringify(o.date_range);
        if (o.date_range.start && o.date_range.end) {
          if (o.date_range_string == o.date_range_applied) return;
          this.tab.report.date.range.applied = o.date_range_string;
          o.params = {start_date: o.date_range.start, end_date: o.date_range.end};
          this.GetWorkDoneReport(o.params).then(data => {
            this.tab.report.data = this.CloneObject(data);
            //console.log(this.tab.report.data)
            this.tab.report.entries = data.punch_clock_ids.length;
            this.tab.report.list = Object.entries(data.res_user_ids).map(entry => {
              const user = {...{id: Number(entry[0])}, ...entry[1]};
              const report = data.report[user.id];
              const result = data.result[user.id];
              const punchclock = report.punch_clock;
              user.order_count = 0;
              user.punch_count = punchclock.commission_count + punchclock.hourly_count;
              user.time = punchclock.commission_time + punchclock.hourly_time;
              user.name = (user.name + ' ' + user.surname).replace(/\s\s+/g, '');
              user.punches = data.punch_clock_ids.filter(punch => {
                if (!(o.params.start_date <= punch.start_date
                 && o.params.end_date >= punch.end_date 
                 && punch.start_date <= o.params.end_date)) {
                   return;
                }
                if (punch.res_user_id == user.id) {
                  punch.time = punch.time_delta || 0;
                  punch.user_id = punch.res_user_id;
                  punch.orders = result ? result.order_pack.filter(order => {
                    if (punch.start_date <= order.start_date && punch.end_date >= order.end_date) {
                      order.id = order.order_overview_id;
                      //delete order.order_overview_id;
                      return order;
                    }
                  }).sort((a, b) => new Date(b.start_date) - new Date(a.start_date)) : [];
                  punch.order_count = punch.orders.length;
                  user.order_count += punch.order_count;
                  delete punch.res_user_id;
                  delete punch.time_delta;
                  return punch;
                }
              }).sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
              delete user.commission_pay;
              delete user.hourly_pay;
              delete user.surname;
              delete user.email;
              if (user.punches.length) {
                return user;
              }
            }).filter(x => x);
            this.tab.report.items = this.tab.report.list;
            const sort = this.tab.report.sort;
            this.Sort(this.tab.report, 'items', sort.by, sort.prop, sort.dir);
            this.UpdateAllNestedListHeights();
            //console.log(this.CloneObject(this.tab.report.list))
            this.$nextTick().then(this.ObserveIntersections_punch_report_list);
          });
        }
      },
      ObserveIntersections_punch_report_list() {
        this.UnobserveIntersections_punch_report_list();
        document.querySelectorAll('#punch-report-list .toggle').forEach(toggle => {
          const observer = new IntersectionObserver( 
            ([e]) => {e.target.toggleAttribute('stuck', e.intersectionRatio < 1)},
            {rootMargin: '-35px 0px 0px 0px', threshold: 1}
          );
          observer.observe(toggle);
          this.tab.report.toggles.push(toggle);
          this.tab.report.observers.push(observer);
        });
      },
      UnobserveIntersections_punch_report_list() {
        this.tab.report.toggles.map((toggle, i) => {
          this.tab.report.observers[i].unobserve(toggle);
        });
        this.tab.report.observers = [];
        this.tab.report.toggles = [];
      },
      async GetWorkDoneReport(params) {
        const promises = [
          new Promise((resolve, reject) => {
            BPA.api.GetPayListInterval(params).then(response => {
              return BPA.api.response({response, return: 'json'});
            }).then(response => {
              if (!response.ok || !response.result) return reject();
              resolve(response.result);
            }).catch(e => e);
          }),
          new Promise((resolve, reject) => {
            BPA.api.GetWorkDoneReport(params).then(response => {
              return BPA.api.response({response, return: 'json'});
            }).then(response => {
              if (!response.ok || !response.result) return reject();
              resolve(response.result);
            }).catch(e => e);
          })
        ];
        this.loading = true;
        return await Promise.allSettled(promises).then(result => {
          let data = {};
          this.loading = false;
          result.map(promise => {
            if (promise.status == 'fulfilled') {
              data = {... data, ...promise.value};
            }
          });
          return data;
        });
      },
      ViewOrders(button = {}, user = {}, punch_id = '') {
        //console.log(button, user, punch_id)
        if (!Object.keys(user).length || !user.punches.length) {
          button.disabled = true;
          return;
        }
        const punch = user.punches.find(punch => punch.id == punch_id);
        if (punch_id && !punch) return;
        this.modal.view.multi = !punch_id;
        this.modal.view.title = this.TitleCase(user.name);
        this.modal.view.list = !punch_id ? user.punches : /*punch.orders*/[punch];
        //console.log(this.modal.view.list)
        const orders = this.CloneObject(punch.orders).sort((a, b) => new Date(a.start_date) - new Date(b.start_date));
        const seconds = (start_date, end_date) => (new Date(end_date) - new Date(start_date)) / 1000;
        const total_seconds = orders.reduce((a, b) => a + b.total_seconds, 0);
        const rounded = (decimal) => Math.round(decimal * 100) / 100;
        const percent = (seconds) => rounded((seconds / total_seconds) * 100);
        const events = [{
          title: 'Punched in',
          type: 'start',
          id: null,
          start: punch.start_date,
          end: punch.start_date,
          seconds: 0,
          percent: 0
        }];
        let previous = null;
        for (let i = 0; i < orders.length; i++) {
          const order = orders[i];
          const event = events[0];
          events.push(
            {
              title: 'Break',
              type: 'break',
              id: null,
              start: !previous ? event.end : previous.end_date,
              end: order.start_date,
              seconds: seconds(!previous ? event.end : previous.end_date, order.start_date),
              percent: percent(seconds(!previous ? event.end : previous.end_date, order.start_date))
            }, {
              title: 'Order',
              type: 'order',
              id: order.id,
              start: order.start_date,
              end: order.end_date,
              seconds: order.total_seconds,
              percent: percent(order.total_seconds)
            }
          );
          previous = order;
        }
        events.push(
          {
            title: 'Break',
            type: 'break',
            id: null,
            start: events.slice(-1)[0].end,
            end: punch.end_date,
            seconds: seconds(events.slice(-1)[0].end, punch.end_date),
            percent: percent(seconds(events.slice(-1)[0].end, punch.end_date))
          }, {
            title: 'Punched out',
            type: 'end',
            id: null,
            start: punch.end_date,
            end: punch.end_date,
            seconds: 0,
            percent: 0
          }
        );
        this.modal.view.scale = 1;
        this.modal.view.events = events;
        //console.log(this.CloneObject(events))
        this.modal.view.open = true;
        this.UpdateAllNestedListHeights();

        // Add order list item elements to the order event objects
        this.$nextTick().then(() => {
          const modal = document.getElementById('view-punch-orders');
          const list = modal.querySelector('.list-row.body');
          //let break_time = 0;
          //let order_time = 0;
          events.map((event, i) => {
            /*
            if (event.type == 'break') {
              break_time += event.seconds;
            }
            */
            if (event.type == 'order') {
              //order_time += event.seconds;
              this.modal.view.events[i].element = list.querySelector('[data-id="' + event.id + '"]');
            }
          });
          //console.log(this.TimeFormat(break_time), this.TimeFormat(order_time))
          //console.log(this.CloneObject(this.modal.view.events))
        });
      },
      TimelineZoom(e) {
        e.preventDefault();
        let element = e.target;
        let timeline = 'timeline';
        if (element.className != timeline) {
          element = element.closest('.' + timeline);
        }
        timeline = element.firstChild;
          
        //console.log(e)

        //this.modal.view.scale = Math.min(Math.max(.125, this.modal.view.scale), 4);
        //console.log(this.modal.view.scale, timeline)
        //timeline.style.transform = `scaleX(${this.modal.view.scale})`;
      },
      TimelineEventHover(event, e) {
        if (event.type != 'order') return;
        const order_element = event.element;
        const hover = e.type == 'mouseenter';
        if (!order_element) return console.error('order element not found');
        order_element.classList[hover ? 'add' : 'remove']('highlighted');
        order_element.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center'
        });
      },
      TimelineEventClick(event) {
        if (event.type != 'order') return;
        const order_element = event.element;
        if (!order_element) return console.error('order element not found');
        const link = order_element.querySelector('a');
        window.open(link.href, '_blank');
      },
      async GetPunchValidationCheck() {
        this.loading = true;
        await BPA.api.GetPunchValidationCheck().then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          this.loading = false;
          if (!response.ok || !response.result) return;
          let data = response.result;
          const punch_list = [];
          const week_numbers = [];
          this.tab.validation.data = this.CloneObject(data);
          this.tab.validation.entries.total = data.punches.length;
          for (let id in data.res_user) {
            punch_list.push({
              id: id,
              name: data.res_user[id].name,
              punches: []
            });
          }
          data.punches.map(punch => {
            const week_number = this.WeekNumber(punch.start_date);
            const punch_user = punch_list.find(user => user.id == punch.res_user_id);
            if (!week_numbers.includes(week_number)) week_numbers.push(week_number);
            punch_user.punches.push({
              id: punch.id,
              type: punch.type,
              week: week_number,
              punched_out: !!punch.end_date,
              date: {
                valid: true,
                start: {
                  utc: punch.start_date,
                  ltz: this.DateToISO(punch.start_date, true)
                },
                end: {
                  utc: punch.end_date || '',
                  ltz: punch.end_date ? this.DateToISO(punch.end_date, true) : ''
                }
              }
            });
          });
          this.tab.validation.week.options = week_numbers.sort((a, b) => b - a);
          this.tab.validation.list = punch_list;
          this.tab.validation.loaded = true;
          this.OnResetDateRange();
        }).catch(e => e);
      },
      OnResetDateRange() {
        let punch_in = '', punch_out = '';
        this.tab.validation.list.map(user => {
          user.punches.map(punch => {
            if (!punch_in || punch_in >= punch.date.start.utc) punch_in = punch.date.start.utc;
            if (!punch_out || punch_out <= punch.date.end.utc) punch_out = punch.date.end.utc;
          });
        });
        this.tab.validation.date.range = {
          start: this.DateToISO(punch_in ? punch_in : new Date(), !!punch_in),
          end: this.DateToISO(punch_out ? punch_out : new Date(), !!punch_out)
        }
        this.OnDateSelection();
      },
      OnDateSelection() {
        const date_range = this.CloneObject(this.tab.validation.date.range);
        const start_week = this.WeekNumber(date_range.start);
        const end_week = this.WeekNumber(date_range.end);
        const this_week = this.WeekNumber(new Date());
        const week_options = this.tab.validation.week.options;
        let week_number = start_week == this_week && end_week == this_week ? this_week : '';
        if (!week_number) for (let i = 0; i < week_options.length; i++) {
          if (start_week == week_options[i] && end_week == week_options[i]) {
            week_number = week_options[i];
            break;
          }
        }
        this.tab.validation.week.selected = week_options.includes(week_number) ? week_number : '';
        if (date_range.start) date_range.start = this.DateToUTC(date_range.start);
        if (date_range.end) date_range.end = this.DateToUTC(date_range.end);
        if (date_range.start && date_range.end) {
          let found = 0;
          const items = [];
          this.tab.validation.list.filter(user => {
            return user.punches.some(punch => {
              const punch_start = punch.date.start.utc;
              const punch_end = punch.date.end.utc;
              const end_date = punch_end || date_range.end;
              return date_range.start <= punch_start && date_range.end >= end_date && end_date >= date_range.start;
            });
          }).map((user, i) => {
            items.push({
              id: Number(user.id),
              name: user.name,
              total_duration: 0,
              weeks: [],
              punches: []
            });
            user.punches.map(punch => {
              const punch_start = punch.date.start.utc;
              const punch_end = punch.date.end.utc;
              const end_date = punch_end || date_range.end;
              if (date_range.start <= punch_start && date_range.end >= end_date && end_date >= date_range.start) {
                punch.duration = this.DateTimeDiff(punch_start, punch_end);
                items[i].total_duration += punch.duration;
                punch.date = {
                  valid: true,
                  start: {
                    utc: punch_start,
                    ltz: this.DateToISO(punch_start, true)
                  },
                  end: {
                    utc: punch_end || '',
                    ltz: punch_end ? this.DateToISO(punch_end, true) : ''
                  }
                }
                punch.start_date = punch_start;
                punch.end_date = punch_end || '';
                if (!items[i].weeks.includes(punch.week)) {
                  items[i].weeks.push(punch.week);
                }
                items[i].punches.push(punch);
                found++;
              }
            });
            items[i].punches.sort((a, b) => new Date(b.date.start.utc) - new Date(a.date.start.utc));
          });
          this.tab.validation.entries.found = found;
          this.tab.validation.items = items;
          const sort = this.tab.validation.sort;
          this.Sort(this.tab.validation, 'items', sort.by, sort.prop, sort.dir);
          this.UpdateAllNestedListHeights();
          //console.log(this.CloneObject(this.tab.validation.items))
          this.$nextTick().then(this.ObserveIntersections_punch_validation_list);
        } else {
          this.tab.validation.entries.found = 0;
          this.tab.validation.items = [];
        }
      },
      ObserveIntersections_punch_validation_list() {
        this.UnobserveIntersections_punch_validation_list();
        document.querySelectorAll('#punch-validation-list .toggle').forEach(toggle => {
          const observer = new IntersectionObserver( 
            ([e]) => {e.target.toggleAttribute('stuck', e.intersectionRatio < 1)},
            {rootMargin: '-35px 0px 0px 0px', threshold: 1}
          );
          observer.observe(toggle);
          this.tab.validation.toggles.push(toggle);
          this.tab.validation.observers.push(observer);
        });
      },
      UnobserveIntersections_punch_validation_list() {
        this.tab.validation.toggles.map((toggle, i) => {
          this.tab.validation.observers[i].unobserve(toggle);
        });
        this.tab.validation.observers = [];
        this.tab.validation.toggles = [];
      },
      OnWeekSelection(option) {
        if (option) {
          const today = new Date();
          const date = this.DateOfWeek(option);
          const day = date.getDay();
          const week = this.WeekNumber(today);
          const month = date.getMonth();
          const year = date.getFullYear();
          const diff = date.getDate() - day + (day == 0 ? -6 : 1);
          const monday = new Date(date.setDate(diff));
          if (diff <= 0) {
            date.setYear(year + 1);
            date.setMonth(month);
            if (diff == 0) {
              date.setMonth(date.getMonth() - 1);
            }
          }
          date.setDate(diff + 6);
          date.setHours(23, 59, 0);
          const sunday = new Date(date);
          this.tab.validation.date.range.start = this.DateToISO(monday);
          this.tab.validation.date.range.end = this.DateToISO(option == week ? today : sunday);
          this.OnDateSelection();
        }
      },
      HandlePunchUpdate(event) {
        if (!event) return;
        const input = event.target;
        input.dispatchEvent(new Event('input'));
        const component = event.component;
        const date = component.value;
        const type = component.name;
        const date_type = type + '_date';
        const punch = this.GetPunchById(component.id);
        const user_id = event.target.closest('.user').dataset.id;
        if (event.type == 'focus') {
          input.date = date;
        }
        if (event.type == 'blur') {
          if (component.valid && input.date != date) {
            this.UpdatePunch(user_id, {id: punch.id, [date_type]: this.DateToUTC(punch.date[type].ltz)});
          }
        }
        if (event.type == 'click') {
          if (component.valid && this.DateToISO(punch[date_type], true) != date) {
            this.UpdatePunch(user_id, {id: punch.id, [date_type]: punch[date_type]});
          }
        }
      },
      ChangePunchDateTime(event) {
        const component = event.component;
        const punch_element = component.closest('.punch');
        const duration_element = punch_element.querySelector('.punch-duration');
        const punch = this.GetPunchById(component.id);
        const date = component.value;
        const type = component.name;
        const duration_invalid = () => {
          punch.date.valid = false;
          duration_element.classList.remove('negative');
          duration_element.dataset.invalid = 'Invalid';
        }
        if (!component.valid) {
          return duration_invalid();
        }
        punch.date.valid = true;
        punch.date[type].ltz = date;
        duration_element.removeAttribute('data-invalid');
        if (!punch.punched_out) return;
        const punch_duration = this.DateTimeDiff(punch.date.start.ltz, punch.date.end.ltz);
        if (punch_duration < 0) {
          component.valid = false;
          component.classList.add('invalid');
          return duration_invalid();
        }
        punch.duration = punch_duration;
        duration_element.textContent = this.TimeFormat(Math.abs(punch.duration));
        punch_element.querySelectorAll('.' + component.classList[0]).forEach(component => {
          if (component.classList.contains('invalid')) duration_invalid();
        });
        duration_element.classList[punch_duration < 0 ? 'add' : 'remove']('negative');
      },
      PunchAction(event, validated = false, all = false, length = 0) {
        const user_id = event.target.closest('.user').dataset.id;
        const user = this.tab.validation.items.find(user => user.id == user_id);
        let invalid_duration = user.punches.filter(punch => !punch.date.valid);
        let not_punched_out = user.punches.filter(punch => !punch.punched_out);
        let message = (validated ? 'Submits' : 'Deletes') + ' the' + (length > 1 ? ' ' + length : '') + ' current punch' + (length > 1 ? 'es' : '') + '.';
        let punches_remaining = user.punches.length;
        let punches = user.punches;
        if (all) {
          if (validated) {
            punches = user.punches.filter(punch => punch.punched_out && punch.date.valid);
            invalid_duration = user.punches.filter(punch => punch.punched_out && !punch.date.valid);
            if (not_punched_out.length || invalid_duration.length) {
              punches_remaining = user.punches.length - (not_punched_out.length + invalid_duration.length);
              message = 'Skipped punch' + (not_punched_out.length + invalid_duration.length != 1 ? 'es' : '') + ':';
              if (not_punched_out.length) {
                message += '\n  • ' + not_punched_out.length + ' punch' + (not_punched_out.length != 1 ? 'es' : '') + ' not punched out.';
              }
              if (invalid_duration.length) {
                message += '\n  • ' + invalid_duration.length + ' punch' + (invalid_duration.length != 1 ? 'es' : '') + ' with invalid duration' + (invalid_duration.length != 1 ? 's' : '') + '.';
              }
              if (punches_remaining) {
                message += '\n\nSubmits the ' + (punches_remaining != 1 ? punches_remaining + ' ' : '') + 'remaining punch' + (punches_remaining != 1 ? 'es' : '') + '.';
              }
            }
          } else {
            punches = user.punches.filter(punch => punch.date.valid);
            if (invalid_duration.length) {
              punches_remaining = user.punches.length - invalid_duration.length;
              message = 'Skipped punch' + (invalid_duration.length != 1 ? 'es' : '') + ':';
              message += '\n  • ' + invalid_duration.length + ' punch' + (invalid_duration.length != 1 ? 'es' : '') + ' with invalid duration' + (invalid_duration.length != 1 ? 's' : '') + '.';
              if (punches_remaining) {
                message += '\n\nDeletes the ' + (punches_remaining != 1 ? punches_remaining + ' ' : '') + 'remaining punch' + (punches_remaining != 1 ? 'es' : '') + '.';
              }
            }
          }
        } else {
          punches = [this.GetPunchById(event.target.closest('.punch').dataset.id)];
          let punch = punches[0];
          let invalid = false;
          let error = '';
          if (validated) {
            if (!punch.punched_out) {
              error = 'The punch is not punched out';
              invalid = true;
            }
          }
          if (!punch.date.valid) {
            error = 'The punch duration is invalid';
            invalid = true;
          }
          if (invalid) {
            this.$eventHub.$emit('ShowMessages', {
              message: error,
              type: 'error',
              hide: 2000
            });
            return;
          }
        }
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, ' + (validated ? 'submit' : 'delete') + (punches.length > 1 ? ' them' : ' it'),
          type: validated ? 'success' : 'danger',
          disapprove: 'No',
          message: message
        });
        if (!punches.length) {
          setTimeout(() => {
            const popup = document.querySelector('.validate-popup__content');
            const actions = popup.querySelector('.actions');
            popup.querySelector('.title').textContent = 'Nothing to ' + (validated ? 'submit' : 'delete');
            actions.querySelector('.btn-stateless').textContent = 'Okay, nevermind then';
            actions.querySelector('.btn-success, .btn-danger').remove();
            actions.style.justifyContent = 'center';
          });
        }
        this.$eventHub.$on('ValidateModalStop', (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          approve && this.SubmitPunch(user.id, punches, validated);
        });
      },
      async SubmitPunch(user_id = '', punches = [], validated = false) {
        const list = this.tab.validation.list;
        const items = this.tab.validation.items;
        const list_user = list.find(user => user.id == user_id);
        const items_user = items.find(user => user.id == user_id);
        this.loading = true;
        const promises = punches.map(async punch => {
          const params = {id: punch.id, validated: validated};
          params.start_date = this.DateToUTC(punch.date.start.ltz);
          params.end_date = this.DateToUTC(punch.date.end.ltz);
          return await this.ValidatePunchCheck(params);
        });
        await Promise.allSettled(promises).then(result => {
          this.loading = false;
          const fulfilled = [];
          const rejected = [];
          let alert_message = '';
          result.map(promise => {
            if (promise.status == 'fulfilled') {
              fulfilled.push(promise.value);
            }
            if (promise.status == 'rejected') {
              rejected.push(promise.reason);
            }
          });
          if (fulfilled.length) {
            if (rejected.length) {
              alert_message += 'Succeeded punch' + (fulfilled.length ? 'es' : '') + ':\n';
            }
            fulfilled.map(punch => {
              list_user && list_user.punches.splice(list_user.punches.findIndex(list_punch => list_punch.id == punch.id), 1);
              items_user && items_user.punches.splice(items_user.punches.findIndex(items_punch => items_punch.id == punch.id), 1);
              if (rejected.length) {
                alert_message += 'Punch ID:' + punch.id + '\n';
                alert_message += '  Start: ' + this.DateFormat(punch.start_date) + '\n';
                alert_message += '  End: ' + (punch.end_date ? this.DateFormat(punch.end_date) : '') + '\n';
              }
            });
            if (rejected.length) {
              alert_message += '\n';
            }
            if (items_user.punches.length) {
              items_user.total_duration = items_user.punches.reduce((a, b) => a + b.duration, 0);
            } else {
              items.splice(items.findIndex(user => user.id == user_id), 1);
            }
            if (!list_user.punches.length) {
              list.splice(list.findIndex(user => user.id == user_id), 1);
            }
            let found = 0, total = 0;
            items.map(user => user.punches.map(() => found++));
            list.map(user => user.punches.map(() => total++));
            this.tab.validation.entries.found = found;
            this.tab.validation.entries.total = total;
            this.UpdateAllNestedListHeights();
          }
          if (rejected.length) {
            alert_message += 'Failed punch' + (rejected.length ? 'es' : '') + ':\n';
            rejected.map(punch => {
              alert_message += 'Punch ID:' + punch.id + '\n';
              alert_message += '  Start: ' + this.DateFormat(punch.start_date) + '\n';
              alert_message += '  End: ' + (punch.end_date ? this.DateFormat(punch.end_date) : '') + '\n';
            });
            this.$eventHub.$emit('ShowAlert', {
              title: (fulfilled.length ? (fulfilled.length + ' punch' + (fulfilled.length > 1 ? 'es' : '') + 'succeeded and ') : '') + rejected.length + ' punch' + (rejected.length > 1 ? 'es' : '') + ' failed',
              message: alert_message.replace(/\\n$/, '')
            });
            return;
          }
          this.$eventHub.$emit('ShowMessages', {
            message: 'Punch' + (punches.length > 1 ? 'es' : '') + ' successfully ' + (validated ? 'submitted' : 'deleted'),
            type: 'success',
            hide: 2000
          });
        });
      },
      async ValidatePunchCheck(params) {
        return await new Promise((resolve, reject) => {
          BPA.api.ValidatePunchCheck(params).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            if (!response.ok) return reject();
            resolve(params);
          }).catch(reject);
        }).catch(e => e);
      },
      FindPunch(punch_id) {
        if (punch_id) return this.tab.validation.items.map(user => user.punches.find(punch => punch.id == punch_id)).filter(punch => !!punch)[0];
      },
      GetPunchById(punch_id) {
        let punch = null;
        if (punch_id) {
          for (let i = 0; i < this.tab.validation.items.length; i++) {
            for (let j = 0; j < this.tab.validation.items[i].punches.length; j++) {
              if (this.tab.validation.items[i].punches[j].id == punch_id) {
                punch = this.tab.validation.items[i].punches[j];
              }
            }
            if (punch) break;
          }
        }
        return punch || {};
      },
      ToggleListItem(event) {
        let element = event.target || event;
        if (!element.classList.contains('toggle')) {
          element = element.closest('.toggle');
          if (!element) return;
        }
        const collapsed = element.classList.contains('collapsed');
        const nested = element.nextSibling;
        if (collapsed) {
          this.SetNestedListHeight(nested);
        } else {
          nested.style.removeProperty('height');
        }
        clearTimeout(this.tab.validation.animate);
        nested.classList.add('animate');
        ['collapsed', 'expanded'].map(class_name => {
          element.classList.toggle(class_name);
        });
        this.tab.validation.animate = setTimeout(() => {
          nested.classList.remove('animate');
        }, 150);
      },
      ToggleReportListItem(event) {
        let element = event.target || event;
        if (!element.classList.contains('toggle')) {
          if (element.parentElement.classList.contains('toggle')) {
            element = element.parentElement;
          } else return;
        }
        const collapsed = element.classList.contains('collapsed');
        const nested = element.nextSibling;
        if (collapsed) {
          this.SetNestedListHeight(nested);
        } else {
          nested.style.removeProperty('height');
        }
        clearTimeout(this.tab.report.animate);
        nested.classList.add('animate');
        ['collapsed', 'expanded'].map(class_name => {
          element.classList.toggle(class_name);
        });
        this.tab.report.animate = setTimeout(() => {
          nested.classList.remove('animate');
        }, 150);
      },
      ToggleModalListItem(event) {
        let element = event.target || event;
        if (!element.classList.contains('toggle')) {
          if (element.parentElement.classList.contains('toggle')) {
            element = element.parentElement;
          } else return;
        }
        const collapsed = element.classList.contains('collapsed');
        const nested = element.nextSibling;
        if (collapsed) {
          this.SetNestedListHeight(nested);
        } else {
          nested.style.removeProperty('height');
        }
        clearTimeout(this.modal.view.animate);
        nested.classList.add('animate');
        ['collapsed', 'expanded'].map(class_name => {
          element.classList.toggle(class_name);
        });
        this.modal.view.animate = setTimeout(() => {
          nested.classList.remove('animate');
        }, 150);
      },
      SetNestedListHeight(element) {
        element.style.removeProperty('height');
        let clone = element.cloneNode(true);
        clone.classList.add('clone');
        element.parentElement.append(clone);
        let rect = clone.getBoundingClientRect();
        element.style.height = rect.height + 'px';
        clone.remove();
      },
      UpdateAllNestedListHeights() {
        setTimeout(() => {
          const items = document.querySelectorAll('.expanded ~ .body');
          items.forEach(this.SetNestedListHeight);
        });
      },
      async ForcePuncOut(user_id, punch_id) {
        await this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, punch out',
          disapprove: 'No',
          message: 'Forces ' + (user_id == this.user_id ? 'yourself' : 'current user') + ' to punch out.',
          type: 'danger'
        });
        this.$eventHub.$on('ValidateModalStop', approve => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          BPA.api.GetServerTime().then(response => {
            return BPA.api.response({response, return: 'text'});
          }).then(response => {
            if (!response.ok || !response.result) return;
            this.UpdatePunch(user_id, {id: punch_id, end_date: response.result}).then(() => {
              this.$eventHub.$emit('ShowMessages', {
                message: 'User successfully punched out',
                type: 'success',
                hide: 2000
              });
            });
          }).catch(e => e);
        });
      },
      async PunchOut(punch_id) {
        return await new Promise((resolve, reject) => {
          BPA.api.PunchOut(punch_id).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            if (!response.ok) return reject();
            this.UpdateLastPunchList();
            if (this.permission.admin) {
              this.search.init.admin = null;
              this.GetAdminSearchList();
            }
            resolve(response);
          }).catch(reject);
        }).catch(e => e);
      },
      async UpdatePunch(user_id, punch = {}) {
        return await new Promise((resolve, reject) => {
          BPA.api.UpdatePunch(punch).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            if (!response.ok) return reject();
            if (user_id == this.user_id) {
              this.UpdateLastPunchList();
            }
            if (this.tab.active == 'workers' && this.permission.admin) {
              this.search.init.admin = null;
              this.GetAdminSearchList();
            }
            if (this.tab.active == 'validation') {
              console.info('Validation - Punch updated')
            }
            resolve(response);
          }).catch(reject);
        }).catch(e => e);
      },
      async SetPunchType(user_id, punch = {}) {
        return await new Promise((resolve, reject) => {
          BPA.api.SetPunchType(punch).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            if (!response.ok) return reject();
            if (user_id == this.user_id) {
              this.UpdateLastPunchList();
            }
            if (this.permission.admin) {
              this.search.init.admin = null;
              //this.GetAdminSearchList();
            }
            resolve(response);
          }).catch(reject);
        }).catch(e => e);
      },
      async TogglePunch() {
        if (!this.punched_in) {
          await BPA.api.PunchIn().then(response => {
            return BPA.api.response({response});
          }).then(response => {
            if (!response.ok) return;
            this.punched_in = true;
            this.UpdateLastPunchList();
            if (this.permission.admin) {
              this.search.init.admin = null;
              this.GetAdminSearchList();
            }
          }).catch(e => e);
        } else {
          await this.PunchOut(this.punch_id).then(() => {
            this.punched_in = false;
            BPA.api.GetPunchOutWorkDoneTodayReport().then(response => {
              return BPA.api.response({response, return: 'json'});
            }).then(response => {
              if (!response.ok || !response.result) return;
              let data = response.result;
              this.$eventHub.$emit('ShowAlert', {
                title: `${this.$t('Work done today')}`, 
                message: `<div id="work-done-report" style="width: 500px; text-align: center;">
                  <div style="display: flex; width: 100%; margin-bottom: 10px; border-bottom: 2px solid lightgray; align-items: center;">
                    <div style="width: 100%;">${this.$t('Hourly rate time')}</div>
                    <div style="width: 100%;">${this.$t('Commission time')}</div>
                    <div style="width: 100%;">${this.$t('Orders')}</div>
                  </div>
                  <div style="display: flex; width: 100%; font-weight: 600; font-size: 22px; line-height: 1; color: #1B577A;">
                    <div style="width: 100%;">${this.TimeFormat(data.hourly_seconds || 0)}</div>
                    <div style="width: 100%;">${this.TimeFormat(data.commission_seconds || 0)}</div>
                    <div style="width: 100%;">${this.NumberFormat(data.order_pack_count || 0)}</div>
                  </div>
                  <div class="salaray" style="position: relative; padding-bottom: 5px; margin-top: 20px;">
                    <div class="calculation" style="filter: blur(8px);">
                      <div style="display: flex; width: 100%; margin-bottom: 10px; border-bottom: 2px solid lightgray;">
                        <div style="width: 100%;">${this.$t('Hourly rate')}</div>
                        <div style="width: 100%;">${this.$t('Commission')}</div>
                        <div style="width: 100%;">${this.$t('Guaranteed salary')}</div>
                        <div style="width: 100%;">${this.$t('Salary total')}</div>
                      </div>
                      <div style="display: flex; width: 100%; font-weight: 600; font-size: 22px; line-height: 1; color: #1B577A;">
                        <div style="width: 100%;">Kr. ${this.CurrencyFormat((data.hourly_pay / 60 / 60) * data.hourly_seconds)}</div>
                        <div style="width: 100%;">Kr. ${this.CurrencyFormat(data.commission_pay * data.order_pack_count)}</div>
                        <div style="width: 100%;">Kr. ${this.CurrencyFormat(data.hourly_guarantee_salary * (data.commission_seconds / 3600).toFixed(2))}</div>
                        <div style="width: 100%;">Kr. ${this.CurrencyFormat(((data.hourly_pay / 60 / 60) * data.hourly_seconds) + (data.commission_pay * data.order_pack_count) + (data.hourly_guarantee_salary * (data.commission_seconds / 3600).toFixed(2)))}</div>
                      </div>
                    </div>
                    <button class="button show-salary green" style="top: 50%;left: 50%;position: absolute;transform: translate(-50%, -50%);">${this.$t('Show my salary')}</button>
                  </div>
                </div>`
              });
              setTimeout(() => {
                const report = document.getElementById('work-done-report');
                const salary = report.querySelector('.salaray');
                const calculation = salary.querySelector('.calculation');
                const popup = report.closest('.alert-content');
                const popup_bottom = popup.querySelector('.alert-action');
                const show_btn = report.querySelector('.show-salary');
                const okay_btn = popup.querySelector('.alert-button');
                const timer = document.createElement('div');
                popup_bottom.style.position = 'relative';
                timer.style.position = 'absolute';
                timer.style.fontSize = '1.5em';
                timer.style.color = 'gray';
                timer.style.right = '0';
                popup_bottom.append(timer);
                let timeout = null;
                let countdown = (seconds) => {
                  if (seconds == 0) {
                    this.$eventHub.$emit('HideAlert');
                    clearTimeout(timeout);
                    return;
                  }
                  timer.innerHTML = seconds < 10 ? '0' + seconds : seconds;
                  return setTimeout(() => {countdown(--seconds)}, 1000);
                }
                timeout = countdown(30);
                show_btn.onclick = () => {
                  show_btn.style.setProperty('display', 'none');
                  calculation.style.removeProperty('filter');
                }
                okay_btn.onclick = () => {
                  clearTimeout(timeout);
                }
              });
            }).catch(e => e);
          }).catch(e => e);
        }
      },
      SetPunchState(last_punch = {}) {
        if (Object.keys(last_punch).length) {
          if (last_punch.start_date) {
            if (last_punch.end_date) {
              this.punch_id = null;
              this.punched_in = false;
            } else {
              this.punch_id = last_punch.id;
              this.punched_in = true;
            }
          } else {
            this.punch_id = null;
            this.punched_in = false;
          }
        } else {
          this.punch_id = null;
          this.punched_in = false;
        }
      },
      async UpdateLastPunchList() {
        await BPA.api.GetLastPunches(10).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok || !response.result) return;
          let punches = response.result || [];
          this.SetPunchState(punches.slice(-1)[0]);
          punches.forEach(punch => punch.type = punch.type + (punch.type == 'hourly' ? ' rate' : ''));
          punches.sort((a, b) => new Date(a.start_date) - new Date(b.start_date));
          this.punches = punches;
          if (this.permission.punch) {
            this.OnDateInput();
          }
        }).catch(e => e);
      },
      async OnDateInput() {
        const interval = this.search.punch.interval;
        const start_date = new Date(interval.start);
        const end_date = new Date(interval.end);
        if ((interval.start && interval.end) && (end_date > start_date)) {
          await BPA.api.GetPunchInterval({
            start_date: Tool.DateToUTC(start_date), 
            end_date: Tool.DateToUTC(end_date)
          }).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) return;
            let list = response.result || {};
            let user_id = JSON.parse(BPA.storage.getItem('user')).user_id;
            list = list.items.filter(punch => punch.res_user_id == user_id && punch.end_date);
            list.sort((a, b) => new Date(a.start_date) - new Date(b.start_date));
            this.search.punch.entries = list.length;
            this.search.punch.list = list;
          });
        } else {
          this.search.punch.entries = 0;
          this.search.punch.list = [];
        }
      },
      async GetAdminSearchList(query = '', input) {
        query = String(query).replace(/\s\s+/g, ' ').trim();
        if (input) input.value = query;
        if (this.tab.active != 'workers') return;
        if (this.search.init.admin == query) return;
        this.loading = true;
        await BPA.api[query ? 'SearchPunches' : 'GetNotPunchedOut'](query).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          this.loading = false;
          if (!response.ok || !response.result) return;
          let list = response.result;
          if (Array.isArray(list)) {
            for (let punch of list) {
              if ('user_display_name' in punch) {
                punch.res_user_name = punch.user_display_name;
                delete punch.user_display_name;
              }
            }
          } else {
            list = list.items.filter(punch => !punch.end_date).map(punch => {
              punch.res_user_name = list.user_display_name[punch.res_user_id];
              punch.commission = punch.type == 'commission';
              return punch;
            });
          }
          list.sort((a, b) => new Date(a.start_date) - new Date(b.start_date));
          this.search.admin.entries = list.length;
          this.search.admin.list = list;
          this.tab.workers.loaded = true;
          this.search.init.admin = query;
        }).catch(e => e);
      },
      async TogglePaymentType(user_id, checkbox) {
        await this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, change type',
          disapprove: 'No',
          message: 'Changes ' + (user_id == this.user_id ? 'your' : 'current user\'s') + ' payment type.',
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          this.SetPunchType(user_id, {
            id: checkbox.dataset.punch_id,
            type: !checkbox.checked ? 'hourly' : 'commission'
          }).then(() => {
            checkbox.checked = !checkbox.checked;
            this.$eventHub.$emit('ShowMessages', {
              message: 'User payment type successfully changed',
              type: 'success',
              hide: 2000
            });
          }).catch(e => e);
        });
      },
      Sort(obj, list, by, prop = '', dir) {
        const sort = obj.sort;
        const name = obj.name;
        const items = obj[list];
        const direction = (a, b) => {
          if (sort.dir == 'asc') {
            return a < b ? -1 : a > b ? 1 : 0;
          } else {
            return b < a ? -1 : b > a ? 1 : 0;
          }
        }
        if (sort.by == by) {
          sort.dir = sort.dir == 'desc' ? 'asc' : 'desc';
        }
        if (dir) sort.dir = dir;
        sort.by = by;
        sort.prop = prop;
        items.sort((a, b) => {
          switch (by) {
            case 'date': 
              if (name == 'validation' || name == 'report') {
                a = new Date(a.punches.slice(-1)[0][prop]);
                b = new Date(b.punches.slice(-1)[0][prop]);
              } else {
                a = new Date(a[prop]);
                b = new Date(b[prop]);
              }
              break;
            case 'time':
            case 'number':
              a = parseInt(a[prop]);
              b = parseInt(b[prop]);
              break;
            case 'text':
              a = a[prop].toUpperCase();
              b = b[prop].toUpperCase();
              break;
            default:
              a = a[prop];
              b = b[prop];
          }
          return direction(a, b);
        });
        //console.log(obj, list, by, sort, prop)
      },
      IsSuperUser() {
        return this.Check([/*0*/ 'admin']);
      },
      IsMainCompany() {
        return BPA.util.IsMainCompany();
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../assets/style/variables/colors';
  @import '../assets/style/variables/fonts';
  @import '../assets/style/variables/icons';

  .form-group {
    padding-top: 20px;
  }

  .dateTimePickerWrapper {
    width: 50%;
    position: absolute;

    div {
      background: red;
    }

    .buttonWrap {
      a.confirm {
        background: #58BF5D;
      }
    }
  }

  .tabs {
    overflow: unset;

    &__body {
      overflow-y: unset;
    }
  }

  .grid-info.nowrap {
    flex-wrap: nowrap;
    
    .grid-heading {
      flex-shrink: 0;
    }

    &.flex-center {
      align-items: center;

      .grid-actions {
        width: 100%;
        justify-content: flex-end;
        margin: 0 0 0 calc(10% + 20px);

        .date-range {
          width: 100%;

          .input {
            width: 100%;
          }
        }

        .select.week {
          width: 100%;
          max-width: 10em;
        }
      }
    }
  }

  .field.sm .field-input {
    height: 34px;
    min-height: 34px;
  }

  .field.sm.has-value:not(.no-label) .field-input {
    padding-top: 10px;
  }

  .field.has-value .field-label {
    transform: translateY(-4px);
  }

  .v-select .dropdown-toggle .clear {
    display: none;
  }

  #punch-validation-list {
    .attention {      
      .flag {
        width: 16px;
        height: 16px;
        margin-left: auto;
        display: inline-block;
        display: none;
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        mask-image: $flag;
        position: relative;
        background-color: $greyFont;
      }

      &-red .flag {
        background-color: $red;
      }

      &-yellow .flag {
        background-color: $yellow;
      }

      &-green .flag {
        background-color: $green;
      }

      .flag {
        &.red {
          background-color: $red;
        }
        &.yellow {
          background-color: $yellow;
        }
        &.green {
          background-color: $green;
        }
      }
    }
    .user-name {
      width: 16%;
    }
    .week-number {
      width: 8%;
    }
    .punch {
      .not-punched-out {
        //background-color: #fff4f4;
      }

      &-in, &-out {
        &, * {
          position: relative;
        }

        &-date-time {
          &-picker {
            &-reset {
              border: 0;
              width: 15px;
              height: 15px;
              padding: 1px;
              outline: none;
              cursor: pointer;
              appearance: none;
              margin-left: 10px;
              border-radius: 50%;
              background-color: transparent;

              &::before {
                width: 100%;
                height: 100%;
                content: '';
                display: block;
                mask-size: contain;
                mask-repeat: no-repeat;
                mask-position: center;
                mask-image: $redo;
                background-color: $blue;
                transform: scaleX(-1);
              }
            }
          }
        }

        &-date {
          &-time {
            width: 22%;
          }
        }

        &-time {
          border: 0;
          width: 100px;
          outline: none;
          color: inherit;
          flex-shrink: 0;
          appearance: none;
          margin-left: 0px;
          font-size: inherit;
          font-family: inherit;
          background-color: transparent;
          box-shadow: 0 2px 0 0 lightGray;
          transition: box-shadow 0.075s ease-in-out;

          &:focus {
            box-shadow: 0 2px 0 0 $blue;
          }

          &:invalid, &.invalid {
            box-shadow: 0 2px 0 0 $red;
          }

          &::-webkit-calendar-picker-indicator {
            border: 0;
            outline: none;
            cursor: pointer;
            border-radius: 50%;
            filter: invert(28%) sepia(69%) saturate(525%) hue-rotate(158deg) brightness(88%) contrast(93%);
          }
        }
      }

      &-duration {
        width: 13%;

        &[data-invalid] {
          color: transparent;
          user-select: none;

          &::before {
            cursor: text;
            display: block;
            color: $textFont;
            position: absolute;
            user-select: all;
            content: attr(data-invalid);
          }
        }

        &.negative::before {
          content: '-';
        }
      }

      &-submit {
        width: 16%;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;

        * + * {
          margin-left: 20px;
        }

        button {
          min-width: 85px;
          padding-left: 10px;
          padding-right: 10px;
        }

        &-count {          
          min-width: 30px;
          margin: 0 15px;
          pointer-events: none;
          text-align: center;
          position: relative;
          display: inline-block;
        }
      }
    }

    button {
      &:disabled {
        &.green {
          //cursor: not-allowed;
        }
      }
    }
  }

  #punch-report-list {
    .user-name {
      width: 20%;
    }

    .punch {
      &-clock {
        width: 10%;
      }

      &-in, &-out {
        &-date-time {
          width: 20%;
        }
      }

      &-duration {
        width: 14%;
      }
    }

    .order {
      &-count {
        width: 14%;
      }

      &-view {
        width: 8%;
      }
    }
  }

  #view-punch-orders {
    .view {
      &-type {
        width: 20%;
      }

      &-start, 
      &-end {
        width: 25%;
      }
      
      &-time {
        width: 12%;
      }

      &-open {
        width: 15%;
      }
    }
  }

  .timeline {
    width: 100%;
    height: 50px;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    flex-direction: column;

    &-events {
      height: 50px;
      display: flex;
      margin-top: auto;
      flex-wrap: nowrap;
      position: relative;
      align-items: center;
      list-style-type: none;

      &::before {
        width: 100%;
        height: 2px;
        content: '';
        display: block;
        position: absolute;
        background-color: $textFont;
      }

      &::after {
        z-index: 1;
        width: 100%;
        height: 100%;
        content: '';
        display: block;
        position: absolute;
        pointer-events: none;
      }

      .event {
        z-index: 1;
        height: 50px;
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;

        &.break {
          &:hover {
            background-color: rgba($red, 0.5);

            &::after {
              color: black;
            }
          }
        }

        &.order {
          cursor: pointer;

          &:nth-child(2n+3) {
            background-color: #e4e4e4;
          }

          &:nth-child(4n+3) {
            background-color: #ececec;
          }

          &:hover, &.hover {
            &:nth-child(2n+3) {
              background-color: #2173c3;
            }

            &:nth-child(4n+3) {
              background-color: #277cd0;
            }

            &::after {
              color: white;
            }
          }
        }

        &:hover {
          z-index: 2;

          &::after {
            top: 0;
            left: 50%;
            z-index: 1;
            width: auto;
            height: auto;
            display: flex;
            font-size: $f-s;
            flex-shrink: 0;
            padding: 10px 10px 5px;
            position: absolute;
            text-align: center;
            white-space: pre;
            flex-direction: column;
            //content: attr(data-event);
            transform: translate(-50%, -100%);
            background-color: inherit;
          }
        }

        &.start, &.end {
          border-left: 2px solid $textFont;

          &:hover {
            &::after {
              content: none;
            }
          }
        }

        &.start {
          border-color: $green;
        }

        &.end {
          border-color: $red;
        }
      }
    }
  }
</style>