<template>
  <div class="simple-date-time-picker">
    <span class="date">
      <p>{{ DateOnly(datetime) }}</p>
      <input type="date" :value="date" @focus="Focus" @blur="Blur" @input="Input" @keydown="Keydown" title="">
    </span>
    <input type="time" :value="time" :step="1" @focus="Focus" @blur="Blur" @input="Input" @keydown="Keydown" title="">
    <button v-if="reset" class="reset" @click="Reset($event), Input($event)" title=""></button>
  </div>
</template>

<script>
  import {Tool} from '@/helpers/Tool';

  export default {
    name: 'SimpleDateTimePicker',
    data() {
      return {
        datetime: this.value,
        date: this.Date(this.value),
        time: this.Time(this.value),
        reset_value: ''
      }
    },
    props: {
      id: {
        default: ''
      },
      name: {
        default: ''
      },
      value: {
        default: ''
      },
      reset: {
        default: true
      }
    },
    created() {
      if (this.reset) this.reset_value = this.value;
    },
    computed: {
      component() {
        return document.querySelector('.simple-date-time-picker');
      },
      inputs() {
        const inputs = {};
        this.component.querySelectorAll('input').forEach(input => inputs[input.type] = input);
        return inputs;
      }
    },
    methods: {
      Date(date) {
        return this.Value(date)[0];
      },
      Time(date) {
        return this.Value(date).slice(-1)[0];
      },
      Value(value) {
        return (value || Tool.DateToISO()).split(/ |t/ig);
      },
      DateOnly(date = Tool.DateToISO(new Date())) {
        return String(Tool.DateFormat(date)).replace(Tool.TimeOnly(date), '');
      },
      CloneObject(object = {}) {
        return Tool.CloneObject(object);
      },
      Focus(event) {
        event.component = event.target.closest('.simple-date-time-picker');
        this.$emit('focus', event);
      },
      Blur(event) {
        event.component = event.target.closest('.simple-date-time-picker');
        this.$emit('blur', event);
      },
      Reset(event) {
        event.component = event.target.closest('.simple-date-time-picker');
        this.$emit('reset', event);
      },
      Input(event) {
        const input = event.target;
        const props = this.CloneObject(this.$props);
        event.component = input.closest('.simple-date-time-picker');
        delete props.reset;
        for (let prop in props) {
          event.component[prop] = props[prop];
        }
        if (event.type == 'click' && this.reset) {
          this.datetime = this.reset_value;
          this.date = this.Date(this.datetime);
          this.time = this.Time(this.datetime);
          event.target.previousSibling.value = this.time;
        } else if (event.type == 'input') {
          if (!input.value || !input.validity.valid) {
            event.component.classList.add('invalid');
            event.component.valid = false;
            this.$emit('input', event);
            return;
          }
          if (input != this.inputs.text) {
            if (input.type == 'time') {
              if (input.value.length < 6) {
                input.value += ':00';
              }
            }
            this[input.type] = input.value;
            this.datetime = this.date + ' ' + this.time;
          }
        }
        event.component.classList.remove('invalid');
        event.component.valid = true;
        props.value = this.datetime;
        for (let prop in props) {
          event.component[prop] = props[prop];
        }
        this.$emit('input', event);
      },
      Keydown(event) {
        this.$emit('keydown', event);
        if (event.target == this.inputs.date) {
          event.preventDefault();
          event.target.blur();
        } else if (event.keyCode == 13) {
          event.target.blur();
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../assets/style/variables/colors';
  @import '../../assets/style/variables/icons';

  .simple-date-time-picker {
    display: inline-flex;
    position: relative;
    align-items: center;
    align-content: center;
    flex-grow: 1;
    flex-shrink: 0;
    box-shadow: 0 2px 0 0 lightGray;
    transition: box-shadow 0.075s ease-in-out;

    &:focus-within {
      box-shadow: 0 2px 0 0 $blue;
    }

    &.invalid {
      box-shadow: 0 2px 0 0 $red;
    }

    .date {
      display: flex;
      position: relative;
      align-items: center;
      cursor: default;
    }

    input {
      border: 0;
      padding: 0;
      width: auto;
      outline: none;
      color: inherit;
      box-shadow: none;
      appearance: none;
      position: relative;
      font-size: inherit;
      font-family: inherit;
      background-color: transparent;

      &[type=date] {
        &::-webkit-clear-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none; 
          -moz-appearance: none;
          appearance: none;
        }
      }

      &[type=date] {
        opacity: 0;
        left: 0;
        width: 100%;
        user-select: none;
        position: absolute;
        //background-color: rgba(pink, 0.5);
        color: transparent;

        @-moz-document url-prefix() {
          clip-path: inset(0 20px 0 0);
          width: calc(100% + 20px) !important;
        }

        &::-webkit-calendar-picker-indicator {
          left: 0;
          margin: 0;
          padding: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          background-position: right;
          background-image: none;
          //background-color: green;
        }
      }

      &[type=time] {
        padding-left: 4px;
      }

      &::-webkit-calendar-picker-indicator {
        border: 0;
        outline: none;
        cursor: default;
        filter: invert(28%) sepia(69%) saturate(525%) hue-rotate(158deg) brightness(88%) contrast(93%);
      }
    }

    .reset {
      border: 0;
      width: 15px;
      height: 15px;
      padding: 1px;
      outline: none;
      cursor: pointer;
      appearance: none;
      margin-left: 4px;
      border-radius: 50%;
      background-color: transparent;

      &::before {
        width: 100%;
        height: 100%;
        content: '';
        display: block;
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        mask-image: $redo;
        background-color: $blue;
        transform: scaleX(-1);
      }
    }
  }
</style>